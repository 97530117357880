<template>
  <v-container fluid class="m-0 p-0">
    <v-row dense class="mt-1" v-if="uc != null">
      <v-col cols="12" class="">
        <v-card class="" v-bg:B>
          <v-card class="m-0 p-0" flat :color="hexTints(area.cor,0.95)">
            <v-container fluid class="">
              <v-row dense align="center" justify="start" class="">
                <v-col cols="auto" class="m-0 p-0 me-2">
                  <v-avatar dark :color="hexTints(area.cor,0.8)" size="32" class="m-0 p-0">
                    <i v-i:duo#note#22 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
                  </v-avatar>
                </v-col>
                <v-col cols="10" class="m-0 p-0 ps-1">
                  <div class="mt-0 line-height-1 f-lato fw-600 fs-10pt text-truncate">
                    Tópicos geradores
                    <span class="mx-1 fs-7pt fw-200">•</span>
                    <span class="fs-10pt f-roboto fw-300">{{qtdeTopicosAntigos}} antigos</span>
                  </div>
                  <div class="line-height-0" v-if="uc.ementa.createdAt != undefined" :style="'color:'+hexShades(area.cor,.6)">
                    <i v-i:duo#clock#12 class="left mt-0" :style="'color:'+hexShades(area.cor,.6)"></i>
                    <span class="left ms-1 mt-2 f-lato fs-8pt fw-300">atualizado {{$moment(uc.ementa.createdAt).fromNow()}}</span>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <v-card :color="hexTints(area.cor,0.98)">
            <v-container fluid class="m-0 p-0">
              <v-row dense>
                <v-col cols="12" class="pe-2 text-right">
                  <v-btn color="" class="ps-0 pe-1 fs-8pt f-lato text-nonecase" outlined x-small @click="$refs.promptdialogref.show()">
                    <i v-i:duo#plus-circle#18 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
                    adicionar todos
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <topicoitemselect :topicos="topicos" :item="topico" :area="area" :uc="uc" v-for="(topico,key,index) in uc.topicos" :key="key+index+refresh" />
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "topicosantigos",
  components: {
    'topicoitemselect': () => import('./topico-item-select.vue'),
  },
  props: {
    area: { default: null, type: Object },
    uc: { default: null, type: Object },
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      topicos: null,
      topicos: {},
      qtdeTopicosAntigos: 0,
      qtdeTopicos: 0,
      ordem: "",
    }
  },
  watch: {},
  mounted: function() {
    var self = this;

    self.qtdeTopicosAntigos = Object.keys(this.uc.topicos).length;

    rdb.ref('/curriculo/topicos/ucs/'+this.uc.id).on('value',function(snapshot) {
      var linha = snapshot.val();
      if(linha == null) {
        self.topicos = {};
        self.qtdeTopicos = 0;
        self.ordem = "";
      } else {
        self.qtdeTopicos = linha.qtde;
        self.ordem = linha.ordem;
        self.topicos = {};
        var vetOrdem = [];
        if(linha.ordem != undefined) {
          vetOrdem = linha.ordem.split(";");
        }
        for(var idx in vetOrdem) {
          self.topicos[vetOrdem[idx]] = linha.topicos[vetOrdem[idx]];
        }
      }
      self.refresh++;
      console.log("self.topicos",self.topicos);
    });

    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
